import React from 'react'
import { graphql } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

import styled from 'styled-components'

import Card from 'react-md/lib/Cards/Card'
import CardText from 'react-md/lib/Cards/CardText'

import { Container, PostContainer } from '../components/common/container'
import Layout from '../components/layout'
import SEO from '../components/seo'

import { FullScreenHeroSection } from '../components/common/hero-section'

const RoundedCard = styled(Card)`
  border-radius: 4px;
`

const FeatureHeroSection = styled(FullScreenHeroSection)`
  h1 {
    margin-bottom: 42px;
  }

  li,
  p {
    color: #333;
    text-shadow: none;
    font-size: 24px;
  }

  p:last-child {
    margin: 0;
  }
`

const FeaturePostContainer = styled(PostContainer)`
  padding: 0;
`

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  const { featuredImage } = frontmatter
  return (
    <Layout>
      <SEO title={`Feature: ${frontmatter.title}`} />
      <div>
        <BackgroundImage
          fluid={{
            base64: featuredImage.childImageSharp.lowRes.dataURI,
            src: featuredImage.childImageSharp.hiRes.dataURI,
          }}
        >
          <FeatureHeroSection>
            <h1>{frontmatter.title}</h1>
            <Card>
              <CardText>
                <FeaturePostContainer>
                  <div className="blog-post-container feature-container">
                    <div className="blog-post">
                      <div
                        className="blog-post-content"
                        dangerouslySetInnerHTML={{ __html: html }}
                      />
                    </div>
                  </div>
                </FeaturePostContainer>
              </CardText>
            </Card>
          </FeatureHeroSection>
        </BackgroundImage>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query(
    $path: String!
    $SQIP_HERO_SHAPE_COUNT: Int
    $SQIP_THUMB_SHAPE_COUNT: Int
  ) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        featuredImage {
          ...HeroImage
        }
      }
    }
  }
`
